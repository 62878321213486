
import request from '@/utils/request';


// 生产记录详情
export function productionTaskDetail(params) {
    return request({ url: `/production_task/${params.id}/`, method: 'get', params })
  }

export function productionOrderUpdate(data){
  return request({ url: `/production_task/${data.id}/`, method: 'patch', data})
}

// 获取产品与物料的关系
export function GoodMaterialsRe(params){
  return request({ url: `/good_materials_re/`, method: 'get', params})
}

// 获取产品与物料的关系
export function UpdateGoodMaterialsRe(data){
  return request({ url: `/good_materials_re/update_materials_relations/`, method: 'post', data})
}

// 获取某个任务的信息
export function ProductionTaskInfo(data) {
  return request({ url: `/production_task/${data.id}/`, method: 'get' })
}

//创建任务流程
export function ProductionTaskCreate(data) {
  return request({ url: `/production_task/`, method: 'post', data })
}
//更新任务流程
export function ProductionTaskUpdate(data) {
  return request({ url: `/production_task/${data.id}/`, method: 'put', data })
}

//记录生产任务状态
export function ProductionStatusRecordCreate(data) {
  return request({ url: `/production_status_re/`, method: 'post', data })
}

// 产品任务流程，产品与流程的关系

export function ProductProcessList(params) {
  return request({ url: `/product_process/`, method: 'get', params })
}

export function ProductProcessCreate(data) {
  return request({ url: `/product_process/`, method: 'post', data })
}

export function ProductProcessUpdate(data) {
  return request({ url: `/product_process/${data.id}/`, method: 'put', data })
}

export function ProductProcessDestroy(data) {
  return request({ url: `/product_process/${data.id}/`, method: 'delete', data })
}


// 生产流程
export function ProcessList(params) {
  return request({ url: `/process/`, method: 'get', params })
}

export function ProcessCreate(data) {
  return request({ url: `/process/`, method: 'post', data })
}

export function ProcessUpdate(data) {
  return request({ url: `/process/${data.id}/`, method: 'put', data })
}

export function ProcessDestroy(data) {
  return request({ url: `/process/${data.id}/`, method: 'delete', data })
}


// 工作量
export function TaskProcessList(params) {
  return request({ url: `/task_process/`, method: 'get', params })
}

export function TaskProcessCreate(data) {
  return request({ url: `/task_process/`, method: 'post', data })
}

export function TaskProcessUpdate(data) {
  return request({ url: `/task_process/${data.id}/`, method: 'put', data })
}

export function TaskProcessDestroy(data) {
  return request({ url: `/task_process/${data.id}/`, method: 'delete', data })
}

// 获得某个任务得完成量
export function TaskDone(params) {
  return request({ url: `/task_process/task_done/`, method: 'get', params })
}

// 待检测的生产单
export function pendingProductionOrderList(params) {
  return request({ url: `/production_task/pending/`, method: 'get', params })
}
